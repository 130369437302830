<template>
  <vue-final-modal class="modal-loyalty-level" @click-outside="closeModal('loyalty-level')">
    <div class="vfm__head">
      <div class="vfm__control vfm__control-left" />

      <div class="vfm__title" />

      <div class="vfm__control vfm__control-right">
        <atomic-icon id="close" class="vfm__close" @click="closeModal('loyalty-level')" />
      </div>
    </div>

    <div class="vfm__body">
      <div class="modal-loyalty-level__images">
        <atomic-image class="modal-loyalty-level__images-back-bg" src="/img/loyalty/loyalty-univerce.png" />

        <div class="modal-loyalty-level__avatar-wrap">
          <div class="modal-loyalty-level__avatar-container">
            <loyalty-avatar size="lg" />
          </div>
        </div>
      </div>

      <div v-if="modalTitle" class="modal-loyalty-level__title">{{ modalTitle }}</div>

      <div class="modal-loyalty-level__description">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'description') }}
      </div>

      <loyalty-level-benefits v-if="levelBenefits.length" class="is-order-active" :levelBenefits="levelBenefits" />

      <button-base type="primary" size="md" @click="clickButton">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'button.label') }}
      </button-base>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';
  import { VueFinalModal } from 'vue-final-modal';
  import type { CIModalsContent } from '~/types';

  const props = defineProps<{
    currentLocaleData: Maybe<CIModalsContent['loyaltyLevel']>;
    defaultLocaleData: Maybe<CIModalsContent['loyaltyLevel']>;
  }>();

  const { closeModal } = useModalStore();
  const { getContent, localizePath } = useProjectMethods();
  const loyaltyStore = useLoyaltyStore();
  const { currentLevelName, loyaltyAccount } = storeToRefs(loyaltyStore);

  const modalTitle = computed(() => {
    const contentTitle = getContent(props.currentLocaleData, props.defaultLocaleData, 'title');
    if (contentTitle) {
      return contentTitle
        .replace('{levelName}', currentLevelName.value)
        .replace('{levelOrder}', loyaltyAccount.value?.currentLevel?.order || '');
    }
    return undefined;
  });

  const levelsBenefitsContentParams = {
    contentKey: 'levels-benefits',
    contentRoute: ['pages', 'loyalty'],
    only: ['rewards'],
  };
  const { getContentData: getLevelsBenefitsContentData } = useContentLogic(levelsBenefitsContentParams);
  const { data: levelsBenefitsContent } = await useLazyAsyncData(getLevelsBenefitsContentData);

  const levelBenefits = computed(() => {
    const contentBenefits = getContent(
      levelsBenefitsContent.value?.currentLocaleData,
      levelsBenefitsContent.value?.defaultLocaleData,
      'rewards.benefits'
    );

    if (!contentBenefits?.length) return [];
    return contentBenefits.map((benefit: { label: string; levels?: string[] }) => ({
      label: benefit.label,
      checked: benefit.levels?.includes(String(loyaltyAccount.value?.currentLevel?.order)),
    }));
  });

  const clickButton = (): void => {
    const url = getContent(props.currentLocaleData, props.defaultLocaleData, 'button.link');
    closeModal('loyalty-level');
    if (url) {
      const router = useRouter();
      router.push(localizePath(url));
    }
  };
</script>

<style src="~/assets/styles/components/modal/loyalty-level.scss" lang="scss" />
